// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

@import "~materialize-css/dist/css/materialize";



body  {
  background: #fff;
  font-family: Montserrat
}
#main-container{
  margin: 30px auto;
}
.gears
{
  max-width: 100%;
}
.site-footer{
  text-transform: uppercase;
  font-size: 12px;
}
.login-box,
.signup-box{
  background-color: white;
  border:1px solid #edeeee;
  border-radius: 3px;
  padding: 10px 0;
  text-align: center;
}
.padding-arribototota{
  margin-top: 25% ;
}

input, select, textarea, p, h1,h2,h3,h4,h5,h6,span{
  color: #125688;
}

.idUsuario{
  font-size: 1rem;
}